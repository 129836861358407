import { Button } from '../components/ui/button'

function Navbar() {
  return (
    <div className='sticky top-0 z-50 bg-black p-4 pl-5'>
      <ul className='flex'>
        <li>
          <a href='/' className='text-white mr-4 hover:underline'>
            Home
          </a>
        </li>
        <li>
          <a href='/tech' className='text-white mr-4 hover:underline'>
            Tech
          </a>
        </li>
        <li>
          <a href='/business' className='text-white hover:underline'>
            Business
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Navbar
