import TechGraph from '../graphs/TechGraph'

function Tech() {
  return (
    <div className='flex justify-center mt-24 transform scale-125 px-10'>
      <div className='max-h-120 overflow-y-auto scrollbar-hidden'>
        <TechGraph />
      </div>
    </div>
  )
}

export default Tech
