import { Graphviz } from 'graphviz-react'

function TechGraph() {
  return (
    <Graphviz
      dot={`graph {
            layout=twopi;
            overlap=scale; 
        
            computer_science [shape="box"];
            data_science [shape="box"];
            mathematics [shape="box"];
            statistics [shape="box"];
            any_degree [shape="box"]
            no_degree [shape="box"]
            
            subgraph computer_science {
                computer_science -- web_developer [dir=forward];
                computer_science -- SWE -- Tech_Lead -- Manager -- VP [dir=forward];
                computer_science -- cybersecurity_analyst [dir=forward];
                computer_science -- data_scientist [dir=forward];
                computer_science -- database_administrator [dir=forward];
            }
        
            subgraph data_science {
                data_science -- data_scientist [dir=forward];
                data_science -- data_analyst [dir=forward];
                data_science -- data_engineer [dir=forward];
                data_science -- machine_learning_engineer [dir=forward];
                data_science -- quantitative_analyst [dir=forward];
            }
        
            mathematics -- quantitative_analyst [dir=forward];
            statistics -- quantitative_analyst [dir=forward];
            computer_science -- quantitative_analyst [dir=forward];
        
            SWE -- Senior_SWE -- distinguished_engineer [dir=forward];
            any_degree -- UX [dir=forward];
            // no_degree -- web_developer [color=blue, dir=forward];
            // any_degree -- web_developer [color=blue, dir=forward];
            no_degree -- web_developer [dir=forward];
            any_degree -- web_developer [dir=forward];

            subgraph LEGEND {
                node [shape=plaintext]
                a [label=<
                <TABLE BORDER="1" CELLBORDER="0" CELLSPACING="0" WIDTH="180">
                    <TR>
                        <TD></TD>
                    </TR>
                    <TR>
                        <TD COLSPAN="2" WIDTH="180" HEIGHT="25"><b>Legend</b></TD>
                    </TR>
                    <TR>
                        <TD WIDTH="25">&#x25AD;</TD> <!-- Rectangle -->
                        <TD WIDTH="110">Major</TD>
                    </TR>
                    <TR>
                        <TD WIDTH="25" BORDERCOLOR="black">&#x25EF;</TD> <!-- Oval -->
                        <TD WIDTH="110">Job Position</TD>
                    </TR>
                    <TR>
                        <TD WIDTH="25">&#x2192;</TD> <!-- Directional Arrow -->
                        <TD WIDTH="110">Path Progression</TD>
                    </TR>
                    <TR>
                        <TD></TD>
                    </TR>
                </TABLE>
                >]
            }
        }
        `}
    />
  )
}

export default TechGraph
