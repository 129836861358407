import { Button } from '../../components/ui/button'
import { ReactComponent as Blob } from './blob.svg'

function PageNotFound() {
  return (
    <>
      <div className='flex flex-col items-center mt-32 mb-10 m-4'>
        <p className='scroll-m-20 text-8xl font-extrabold tracking-wide'>404.</p>
        <p className='scroll-m-20 text-lg font-semibold mt-24 text-center lg:text-xl'>
          Uhhh... looks like you've lost your path. We can help you find one.
        </p>
        <Button asChild className='bg-primary text-primary-foreground rounded-3xl hover:bg-primary/90 px-5 py-3 mt-10'>
          <a href='/'>Click here</a>
        </Button>
      </div>
      <Blob className='absolute bottom-6 left-16' />
    </>
  )
}

export default PageNotFound
