import { useNavigate } from 'react-router-dom'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select'

export function Home() {
  const navigate = useNavigate()

  const handleSelectChange = (value: any) => {
    navigate(`/${value}`)
  }

  return (
    <div className='flex flex-col items-center mt-24 mb-10'>
      <p className='scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl'>Find your career path.</p>

      <p className='scroll-m-20 text-xl font-semibold tracking-tight mt-10'>Start by selecting your major:</p>

      <div className='mt-2 mb-6'>
        <Select onValueChange={handleSelectChange}>
          <SelectTrigger className='w-[280px]'>
            <SelectValue placeholder='Select a major' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Tech</SelectLabel>
              <SelectItem value='tech'>Computer Science</SelectItem>
              <SelectItem value='tech'>Data Science</SelectItem>
              <SelectItem value='tech'>Statistics</SelectItem>
            </SelectGroup>
            <SelectGroup>
              <SelectLabel>Business</SelectLabel>
              <SelectItem value='business'>Accouting</SelectItem>
              <SelectItem value='business'>Business</SelectItem>
              <SelectItem value='business'>Economics</SelectItem>
              <SelectItem value='business'>Marketing</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      <p className='text-sm leading-7 [&:not(:first-child)]:mt-64'>Don't see your major?</p>
      <a
        href='/path'
        className='bg-primary text-sm mt-2 text-primary-foreground hover:bg-primary/90 rounded-2xl px-5 py-1'
      >
        Add Career
      </a>
    </div>
  )
}

export default Home
