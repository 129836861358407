import React, { useState } from 'react'
import { Button } from '../components/ui/button'
import { Input } from '../components/ui/input'
import { Textarea } from '../components/ui/textarea'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Message: '',
  })

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    // Convert formData to a URL-encoded string
    const formDataEncoded = new URLSearchParams(formData).toString()

    fetch(
      'https://script.google.com/macros/s/AKfycbx8auCVN_b_SK591fe3SW2-nzID6bxKSPo_03DB_0WYCNWpE15C3BgUJbENde5IFDz2TQ/exec',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Set the correct content-type
        },
        body: formDataEncoded, // Send the URL-encoded form data
      },
    )
      .then((res) => res.text())
      .then((data) => {
        console.log(data)
        // Optionally, you can reset the form after successful submission:
        setFormData({
          Name: '',
          Email: '',
          Message: '',
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className='flex flex-col justify-center items-center h-screen'>
      <p className='title-font text-5xl relative z-10 mb-4'>Let's Talk</p>
      <p className='text-font text-xl py-4 mb-4'>Join us in redefining career paths.</p>{' '}
      <form onSubmit={handleSubmit} className='w-full max-w-xl bg-white bg-opacity-10 p-6 rounded-md'>
        <div className='mb-4'>
          <div className='mr-2'>
            <label htmlFor='Name' className='block text-font text-white mb-1'>
              Name
            </label>
            <Input
              type='text'
              name='Name'
              value={formData.Name}
              onChange={handleInputChange} // Use handleInputChange for Input components
              placeholder='Enter your name'
              required
            />
          </div>
        </div>

        <div className='mb-4'>
          <div className='mr-2'>
            <label htmlFor='Email' className='block text-font text-white mb-1'>
              Email
            </label>
            <Input
              type='text'
              name='Email'
              value={formData.Email}
              onChange={handleInputChange} // Use handleInputChange for Input components
              placeholder='name@email.com'
              required
            />
          </div>
        </div>

        <div className='mb-4'>
          <div className='mr-2'>
            <label htmlFor='Education1' className='block text-font text-white mb-1'>
              Message
            </label>
            <Textarea
              className='h-40 resize-none px-4 py-2 w-full rounded-md border'
              name='Message'
              value={formData.Message}
              onChange={handleInputChange} // Use handleInputChange for textarea
              placeholder='Hey...'
              required
            />
          </div>
        </div>

        <div className='flex mb-4'>
          <div className='mr-2 w-1/2'></div>
        </div>
        <div className='flex justify-center'>
          <Button type='submit' className='text-font text-white indigo hover:bg-blue-700'>
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
