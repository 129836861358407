import React from 'react'
import Tech from './pages/Tech'
import './App.css'
import Navbar from './components/Navbar'
import { Routes, Route } from 'react-router-dom'
import Business from './pages/Business'
import ContactForm from './components/ContactForm'
import { Home } from './pages/Home'
import CareerForm from './pages/CareerForm'
import PageNotFound from './pages/404'

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/tech' element={<Tech />} />
        <Route path='/business' element={<Business />} />
        <Route path='/contact' element={<ContactForm />} />
        <Route path='/' element={<Home />} />
        <Route path='/path' element={<CareerForm />} />
        <Route path='/*' element={<PageNotFound />} />
      </Routes>
    </>
  )
}

export default App
