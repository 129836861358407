import { Graphviz } from 'graphviz-react'

function BusinessGraph() {
  return (
    <Graphviz
      dot={`graph {
            layout=twopi;
            overlap=scale; 
        
            business [shape="box"];
            economics [shape="box"];
            accounting [shape="box"]
            marketing [shape="box"]

            subgraph accounting {
                accounting -- tax_analyst [dir=forward]
                accounting -- budget_analyst [dir=forward]
                accounting -- accountant [dir=forward]
                accounting -- auditor [dir=forward]
            }
        
            subgraph business {
                business -- investment_banking -- private_equity [dir=forward]
                business -- consulting [dir=forward]
                business -- finance [dir=forward];
                business -- budget_analyst [dir=forward]
            }
            
            subgraph economics {
                economics -- investment_banking [dir=forward]
                economics -- human_resource_manager [dir=forward]
                economics -- consulting [dir=forward]
                economics -- research_assistant -- researcher [dir=forward]
                //economics -- business_analyst -- consulting [dir=forward]
            }

            subgraph marketing {
                marketing -- social_media_manager [dir=forward]
                marketing -- budget_analyst [dir=forward]
                marketing -- digital_marketing [dir=forward]
                marketing -- event_planner [dir=forward]
            }

            subgraph LEGEND {
                node [shape=plaintext]
                a [label=<
                <TABLE BORDER="1" CELLBORDER="0" CELLSPACING="0" WIDTH="180">
                    <TR>
                        <TD></TD>
                    </TR>
                    <TR>
                        <TD COLSPAN="2" WIDTH="180" HEIGHT="25"><b>Legend</b></TD>
                    </TR>
                    <TR>
                        <TD WIDTH="25">&#x25AD;</TD> <!-- Rectangle -->
                        <TD WIDTH="110">Major</TD>
                    </TR>
                    <TR>
                        <TD WIDTH="25" BORDERCOLOR="black">&#x25EF;</TD> <!-- Oval -->
                        <TD WIDTH="110">Job Position</TD>
                    </TR>
                    <TR>
                        <TD WIDTH="25">&#x2192;</TD> <!-- Directional Arrow -->
                        <TD WIDTH="110">Path Progression</TD>
                    </TR>
                    <TR>
                        <TD></TD>
                    </TR>
                </TABLE>
                >]
            }
        }
        `}
    />
  )
}

export default BusinessGraph

// import { Graphviz } from 'graphviz-react'

// function BusinessGraph() {
//   return (
//     <Graphviz
//       dot={`digraph {
//         // Set default node and edge attributes
//         node [shape=box, style="rounded,filled", fillcolor="#ffffff", fontname="Arial", fontsize=12];
//         edge [fontname="Arial", fontsize=10, arrowsize=0.5];

//         // Set graph attributes
//         graph [layout=twopi, overlap=scale, splines=true];

//         // Define node clusters
//         subgraph cluster_accounting {
//           label="Accounting";
//           color="#f0f0f0";
//           style="rounded";
//           accounting -> tax_analyst;
//           accounting -> budget_analyst;
//           accounting -> accountant;
//           accounting -> auditor;
//         }

//         subgraph cluster_business {
//           label="Business";
//           color="#f0f0f0";
//           style="rounded";
//           business -> investment_banking -> private_equity;
//           business -> consulting;
//           business -> finance;
//           business -> budget_analyst;
//         }

//         subgraph cluster_economics {
//           label="Economics";
//           color="#f0f0f0";
//           style="rounded";
//           economics -> investment_banking;
//           economics -> human_resource_manager;
//           economics -> consulting;
//           economics -> research_assistant -> researcher;
//         }

//         subgraph cluster_marketing {
//           label="Marketing";
//           color="#f0f0f0";
//           style="rounded";
//           marketing -> social_media_manager;
//           marketing -> budget_analyst;
//           marketing -> digital_marketing;
//           marketing -> event_planner;
//         }

//         // Define node and edge attributes
//         accounting [label="Accounting", fillcolor="#ed7d31"];
//         business [label="Business", fillcolor="#4472c4"];
//         economics [label="Economics", fillcolor="#a5a5a5"];
//         marketing [label="Marketing", fillcolor="#ffc000"];
//         investment_banking [label="Investment Banking", fillcolor="#5b9bd5"];
//         private_equity [label="Private Equity", fillcolor="#5b9bd5"];
//         consulting [label="Consulting", fillcolor="#70ad47"];
//         finance [label="Finance", fillcolor="#4472c4"];
//         human_resource_manager [label="HR Manager", fillcolor="#a5a5a5"];
//         research_assistant [label="Research Assistant", fillcolor="#a5a5a5"];
//         researcher [label="Researcher", fillcolor="#a5a5a5"];
//         social_media_manager [label="Social Media Manager", fillcolor="#ed7d31"];
//         digital_marketing [label="Digital Marketing", fillcolor="#ffc000"];
//         event_planner [label="Event Planner", fillcolor="#ffc000"];

//         // Define edge styles
//         edge [color="#808080"];
//       }`}
//     />
//   )
// }

// export default BusinessGraph
