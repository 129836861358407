import React, { useState } from 'react'
import { Button } from '../components/ui/button'
import { Input } from '../components/ui/input'
import { Textarea } from '../components/ui/textarea'

const CareerForm = () => {
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    LinkedIn: '',
    Major: '',
    Path: '',
  })

  const [submitted, setSubmitted] = useState(false) // Track form submission

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()

    // Convert formData to a URL-encoded string
    const formDataEncoded = new URLSearchParams(formData).toString()

    fetch(
      'https://script.google.com/macros/s/AKfycbyA5xKu1ekM7oYJcdo0gt5VezbkiF02-OyQYqsymSdOigULcsUT5EK4fzYJ2gT4ahFNZw/exec',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Set the correct content-type
        },
        body: formDataEncoded, // Send the URL-encoded form data
      },
    )
      .then((res) => res.text())
      .then((data) => {
        console.log(data)
        // Optionally, you can reset the form after successful submission:
        setFormData({
          FirstName: '',
          LastName: '',
          Email: '',
          LinkedIn: '',
          Major: '',
          Path: '',
        })
        setSubmitted(true) // Set submitted state to true
      })
      .catch((error) => {
        console.log(error)
      })
  }

  if (submitted) {
    return (
      <div className='flex flex-col items-center mt-16'>
        <p className='scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mt-4 mb-6'>Thank You!</p>
        <p className='scroll-m-20 text-lg font-semibold tracking-tight lg:text-xl mb-6 text-center'>
          Your submission has been received, and we'll get your experience on the graph ASAP.
        </p>
      </div>
    )
  }

  return (
    <div className='flex flex-col items-center mt-16'>
      <p className='scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mt-4 mb-6'>Share your journey</p>
      <p className='scroll-m-20 text-lg font-semibold tracking-tight lg:text-xl mb-6 mx-2 text-center'>
        Inspire others by adding your experience to the career graphs.
      </p>

      <form onSubmit={handleSubmit} className='w-full max-w-xl bg-white bg-opacity-10 p-6 rounded-md'>
        <div className='mb-4'>
          <div className='mr-2'>
            <label htmlFor='Name' className='block text-font mb-1'>
              First Name
            </label>
            <Input
              type='text'
              name='FirstName'
              value={formData.FirstName}
              onChange={handleInputChange} // Use handleInputChange for Input components
              placeholder=''
              required
            />
          </div>
        </div>

        <div className='mb-4 mr-2'>
          <label htmlFor='Name' className='block text-font mb-1'>
            Last Name
          </label>
          <Input
            type='text'
            name='LastName'
            value={formData.LastName}
            onChange={handleInputChange} // Use handleInputChange for Input components
            placeholder=''
            required
          />
        </div>

        <div className='mb-4'>
          <div className='mr-2'>
            <label htmlFor='Email' className='block text-font mb-1'>
              Email
            </label>
            <Input
              type='text'
              name='Email'
              value={formData.Email}
              onChange={handleInputChange} // Use handleInputChange for Input components
              placeholder='name@email.com'
              required
            />
          </div>
        </div>

        <div className='mb-4 mr-2'>
          <label htmlFor='Email' className='block text-font mb-1'>
            LinkedIn Profile
          </label>
          <Input
            type='text'
            name='LinkedIn'
            value={formData.LinkedIn}
            onChange={handleInputChange} // Use handleInputChange for Input components
            placeholder='https://'
            required
          />
        </div>

        <div className='mb-4 mr-2'>
          <label htmlFor='Email' className='block text-font mb-1'>
            Major
          </label>
          <Input
            type='text'
            name='Major'
            value={formData.Major}
            onChange={handleInputChange} // Use handleInputChange for Input components
            placeholder='Data Science, BA'
            required
          />
        </div>

        <div className='mb-4'>
          <div className='mr-2'>
            <label htmlFor='Education1' className='block text-font mb-1'>
              Path
            </label>
            <p className='text-xs italic mb-3'>
              Ex: Mechanical Engineering, BS &ndash;&gt; Manufacturing Engineer @ Kleenex &ndash;&gt; MBA from USC
              &ndash;&gt; Product Manager @ Uber
            </p>
            <Textarea
              className='h-40 resize-none px-4 py-2 w-full rounded-md border'
              name='Path'
              value={formData.Path}
              onChange={handleInputChange} // Use handleInputChange for textarea
              placeholder=''
              required
            />
          </div>
        </div>

        <div className='flex mb-4'>
          <div className='mr-2 w-1/2'></div>
        </div>

        <div className='flex justify-center'>
          <Button type='submit' className='text-font text-white indigo hover:bg-blue-700'>
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default CareerForm
