import BusinessGraph from '../graphs/BusinessGraph'

function Business() {
  return (
    <div className='flex justify-center mt-24 transform scale-125'>
      <div className='max-h-120 overflow-y-auto scrollbar-hidden'>
        <BusinessGraph />
      </div>
    </div>
  )
}

export default Business
